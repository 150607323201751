// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    // production: false,
    // websocketUrl: 'ws://localhost:8080',
    // chatSocketURL: 'ws://localhost:8080/chat/',
    // mediaUrl: 'http://localhost:8080/media/picture/',
    // api: "http://localhost:8080",
    // googleKey : "AIzaSyA5JTU3eVMyupROSA7cNVO1ApktovKN4Kc"
    production: true,
    websocketUrl: 'wss://intraav2.agiragri.com',
    chatSocketURL: 'wss://intraav2.agiragri.com/chat/',
    mediaUrl: 'https://intraav2.agiragri.com/media/picture/',
    api: "https://intraav2.agiragri.com/api",
    googleKey: "AIzaSyBI0Pybl_8TclZfTI2I7E7IwNsYEOAVI_E"
};
